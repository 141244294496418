// import { useState, useEffect, useContext } from 'react'
import { BottomBlobInterface } from './BottomBlob.interface'
import Corner from '../../../resources/images/corner.svg'
import { BookAdvertising } from '../../molecules'

const BottomBlob = ({testID }:BottomBlobInterface) => {

  const style = { backgroundImage: `url(${Corner})` }

    return(
        <div data-testid={ testID }
             style={style}
             className={ `BottomBlob` }>
          <BookAdvertising />
        </div>
    )

}

export default BottomBlob
