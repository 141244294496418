import { MenuItemInterface } from './MenuItem.interface'
import { Link } from 'react-router-dom'

const MenuItem = ({ testID, item, active = false, click}: MenuItemInterface) => {
    return (
        <li data-testid={testID} className={`MenuItem `}>
            <Link to={item.url} className={`${ active ? 'active' : ''}`} onClick={() => click(false)}>{item.label}</Link>
        </li>
    )
}

export default MenuItem
