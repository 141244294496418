import { useEffect, useState } from 'react'
import DATA from '../data/content.json'
import { dataType } from '../__types__'
import ADDITIONAL_DATA from '../data/additional-content.json'

const useData = () => {

  const [data, setData] = useState<dataType|null>(null)
  const [additional, setAdditional] = useState<dataType|null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setData(DATA as unknown as dataType)
    setAdditional(ADDITIONAL_DATA as unknown as dataType)
    setLoaded(true)
  }, [])


  return { loaded, data, additional}
}

export default useData