import { ContactInfoInterface } from './ContactInfo.interface'
import { Col, Row } from 'react-bootstrap'
import { config } from '../../../config'
import { Link } from 'react-router-dom'

const ContactInfo = ({testID }:ContactInfoInterface) => {

  const { title, content, address,  mail, phone } = config.contact

    return(
      <>
        <div data-testid={ testID }
             className={ `ContactInfo` }>
          <Row>
            <Col md={12} sm={12} xs={6} className={'left'}>
              <h3>{title}</h3>
              <p>{content}</p>
            </Col>
            <Col md={12} sm={12} xs={6} className={'right'}>
              <a href={mail.link}>{mail.label}</a>
              <br/><br/>
              {address.street}<br />
              {address.postalCode} {address.city}<br />
              {phone.label}&nbsp;<a href={phone.link}> {phone.number}</a><br />
            </Col>
            <Col md={12} className={'bottom-links'}>
              <a rel="nofollow"
                 href={'https://majellalammers.nl'}
                 target={'_blank'}><i
                className="bi bi-globe"></i></a>

              <a rel="nofollow"
                 href={'https://www.facebook.com/profile.php?id=61557249966039'}
                 target={'_blank'}><i
                className="bi bi-facebook"></i></a>

              <a rel="nofollow" href={'https://www.instagram.com/hypnotherapy_for_kids/'} target={'_blank'}><i
                className="bi bi-instagram"></i></a>

              <a rel="nofollow" href={'https://www.linkedin.com/in/majellalammers/?originalSubdomain=nl'} target={'_blank'}><i
                className="bi bi-linkedin"></i></a>

              <a rel="nofollow" href={'https://www.youtube.com/@majellalammersnl'} target={'_blank'}><i
                className="bi bi-youtube"></i></a>

            </Col>
            <Col md={12} className={'bottom-links'}>
              <a href={'/privacy'}>PRIVACY</a>
              <a href={'/algemene-voorwaarden'}>ALGEMENE VOORWAARDEN</a>
            </Col>
          </Row>

        </div>

      </>

    )

}

export default ContactInfo
