import React from 'react'
import {MainPage} from "./components/pages";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useData } from './hooks';
import { pageType } from './__types__/'
import { Navigation } from './components/templates'

const App = () => {

  const { loaded, data, additional } = useData()

  const renderRoutes = () => {
    if(loaded) {
      return (
          <>
          {
            data && data?.menuItems.map((page: pageType) => <Route key={page.url} path={`/${page.url}`} element={<MainPage page={page} />} />)
          }
          </>

      )
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigation menuItems={data} />}>
            {renderRoutes()}
        </Route>
        <Route element={<Navigation menuItems={data} />}>
          { additional && <Route path={`/privacy`} element={<MainPage page={additional?.menuItems[0]} /> } /> }
          { additional && <Route path={`/algemene-voorwaarden`} element={<MainPage page={additional?.menuItems[1]} /> } /> }
        </Route>
      </Routes>
    </BrowserRouter>
  )

}

export default App
