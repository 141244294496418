import { SignatureTitleInterface } from './SignatureTitle.interface'

const SignatureTitle = ({testID, text, type }:SignatureTitleInterface) => {

    return(
        <div data-testid={ testID }
             data-object-type={ type ?? ""}
             className={ `SignatureTitle` }>
          { text }
        </div>
    )

}

export default SignatureTitle
