// import { useState, useEffect, useContext } from 'react'
import { Logo } from '../../atoms'
import Menu from '../Menu'
import { HeaderInterface } from './Header.interface'

const Header = ({ testID, menuItems, current }: HeaderInterface) => {
    return (
        <div data-testid={testID} className={`Header`}>
          <Logo />
          <Menu menuItems={menuItems} current={current}/>
        </div>
    )
}

export default Header
