import { NavigationInterface } from './Navigation.interface'
import { Outlet, useLocation } from "react-router-dom"
import { Header } from '../../organisms'



const Navigation = ({ testID, menuItems }: NavigationInterface) => {

  const location = useLocation()
  const path = location.pathname

    return (
      <div data-testid={testID}
           className={`Navigation`}>
        <Header menuItems={menuItems?.menuItems} current={path}/>
        <Outlet />
      </div>
    )
}

export default Navigation
