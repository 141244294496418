import { MenuInterface } from './Menu.interface'
import { MenuItem } from '../../atoms'
import Hamburger from 'hamburger-react'
import { useState } from 'react'

const Menu = ({ testID, menuItems, current }: MenuInterface) => {

    const [isOpen, setOpen] = useState(false)


  const renderOverlay = () => {
      if(isOpen) {
        return (
          <div className={`overlay`}>
            {renderItems()}
          </div>
        )
      }
  }

    const renderItems = () => {
      return(
        <ul>
          {menuItems && menuItems.map((item, index) => {
            return <MenuItem item={item} key={index} active={ item.url === current } click={setOpen}/>
          })}
        </ul>
      )
    }
    return (
        <div data-testid={testID} className={`Menu`}>
          <div className={'menu-desktop'}>
            {renderItems()}
          </div>
          <div className={'menu-mobile'}>
            <div className={'menu-trigger'} style={{zIndex: 2000}}>
              <Hamburger toggled={isOpen} toggle={setOpen} size={30}/>
            </div>
            { renderOverlay() }
          </div>
        </div>
    )
}

export default Menu
