const signature = 'Majella'

const bookInfo = {
  title: 'Koop mijn boek',
  label: 'Nu voor',
  price: '€ 21,95',
  content: '25-35% van de kinderen en jongeren tussen 4 en 25 jaar heeft last van aanhoudende lichamelijke/mentale onbegrepen klachten. Wij, de omringende volwassenen, zijn vanuit een goede intentie geneigd met onze overtuigingen en oplossingen voor hen te komen. We luisteren niet (voldoende) of pas in een later stadium naar het kind...',
  info: 'https://www.majellalammers.nl/boek-laat-maar-je-snapt-het-niet/',
  buy: 'https://buy.stripe.com/7sI4iEcAv9Y21xu28a',
  image: 'https://www.majellalammers.nl/wp-content/uploads/2024/03/majella-lammers_laat-maar-je-snapt-het-niet_cover.jpeg'
}

const bookingInfo = {
  title: 'Boek mij',
  content: 'Als gastspreker wil ik kleine of grote groepen mensen inspireren met een van deze verhalen. Het verhaal kan ik, wanneer ik ruimschoots van te voren weet wat het thema of de doelgroep is, speciaal afstemmen qua taalgebruik, voorbeelden en kernboodschap.',
  link: 'https://www.majellalammers.nl/spreker/'
}

const contact = {
  title: 'Wat kan ik voor je doen?\n',
  content: 'Heb je een vraag of een opmerking? Twijfel dan niet om contact met me op te nemen.\n',
  address: {
    street: 'Achter den Hof 86',
    postalCode: '6436 EH',
    city: 'Amstenrade'
  },
  mail: { label: 'spreken@all-about-talking.nl\n', link: 'mailto:spreken@all-about-talking.nl\n'},
  phone: { label: 'Telnr.', number: '0628773744', link: 'tel:0628773744'}
}

const podcast = {

}

export const config = { signature, bookInfo, bookingInfo, contact, podcast }