import { ParagraphInterface } from './Paragraph.interface'
import parse from 'html-react-parser'
const Paragraph = ({testID, type = 'regular', renderer = 'text', text }:ParagraphInterface) => {

    return(
        <p data-testid={ testID }
             data-object-type={ type ?? ""}
             className={ `Paragraph` }>
          { renderer === 'text' && text }
          { renderer === 'html' && parse(text) }
        </p>
    )

}

export default Paragraph
