// import { useState, useEffect, useContext } from 'react'
import { HeadingInterface } from './Heading.interface'

/// TODO: update interface/arguments for Heading
const Heading = ({testID, text, type }:HeadingInterface) => {

  const CustomTag: any = type?.replace(' ', '')

  return(
        <CustomTag data-testid={ testID }
             data-object-type={ type ?? ""}
             className={ `Heading` }>
          { text }
        </CustomTag>
    )

}

export default Heading
