import { LogoInterface } from './Logo.interface'

import LogoImage from '../../../resources/images/logo-magenta.png'

const Logo = ({ testID }: LogoInterface) => {
    return (
        <a href={'/'} data-testid={testID} className={`Logo`}>
            <img src={LogoImage} alt='' />
        </a>
    )
}

export default Logo
