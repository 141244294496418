// import { useState, useEffect, useContext } from 'react'
import { BookAdvertisingInterface } from './BookAdvertising.interface'
import {
  Button,
  Paragraph,
  SignatureTitle
} from '../../atoms'
import {config} from '../../../config'

const BookAdvertising = ({testID }:BookAdvertisingInterface) => {

  const { title, label, price, image, content, info, buy } = config.bookInfo

    return(
        <div data-testid={ testID }
             className={ `BookAdvertising` }>
          <div className={'book-description'}>
            <div className={'book-content'}>
              <SignatureTitle type={'prompt'} text={title}/>
              <h3>
                <span className={'label'}>{ label }</span>&nbsp;
                <span className={'price'}>{ price }</span>
              </h3>
              <Button text={'Koop hier'} type={'primary'} link={buy} />
            </div>
            <div className={'book-image'}>
              <img src={image} alt={title} />
            </div>
          </div>
          <div className={'book-info'}>
            <Paragraph text={content} type={'small'} renderer={'text'}/>
            <Button text={'Meer info'} type={'secondary'} link={info}/>
          </div>
        </div>
    )

}

export default BookAdvertising
