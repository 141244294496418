import { BookingInterface } from './Booking.interface'
import {
  Button,
  Paragraph,
  SignatureTitle
} from "../../atoms"

import {config} from '../../../config'

const Booking = ({testID }:BookingInterface) => {

  const { title, content, link } = config.bookingInfo

    return(
        <div data-testid={ testID }
             className={ `Booking` }>
          <SignatureTitle text={ title } type={'title'}/>
          <Paragraph text={ content } type={'small'} renderer={'text'}/>
          <div className='ButtonBar'>
            <Button text={title} link={link} />
          </div>
        </div>
    )

}

export default Booking
