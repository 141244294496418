import { BlobInterface } from './Blob.interface'

const Blob = ({ testID, children, type = 'small'}: BlobInterface) => {
    return <div data-testid={testID}
                data-object-type={type ?? ''}
                className={`Blob`}>
      { children}
    </div>
}

export default Blob
