import { HeroInterface } from './Hero.interface'
import { Row, Col } from 'react-bootstrap'
import {config} from "../../../config";
import {SignatureTitle} from "../../atoms";
import YouTube from 'react-youtube'

const Hero = ({ testID, info, type = 'left' }: HeroInterface) => {

    const renderItem = (position: string) => {
      return(
        <>
        <div className={position === 'left' ? 'desktop content-left' : 'desktop content-right'}>
            <p className={'brooks'}>{ info.slogan }</p>
            <div className={'signature'}>
                <SignatureTitle text={info.signature as string} type={'signature'} />
            </div>
        </div>
          <div className={'mobile'}>
            <p className={'brooks'}>{info.slogan}</p>
            <div className={'signature'}>
              <SignatureTitle
                text={info.signature as string}
                type={'signature'} />
            </div>
          </div>
        </>
      )
    }

  const renderContent = () => {
    return (
      <Row className={'content'}>
        <Col md={6}>
          {type === 'left' && renderItem('left') }
              &nbsp;
            </Col>
            <Col md={6}>
              { type === 'right' && renderItem('right') }
              &nbsp;
            </Col>
          </Row>
        )
    }


    return (
      <div data-testid={testID}
           data-object-type={type ?? ''} className={`Hero`}>

        { !info.video &&
          <div className="shape-div" style={{backgroundImage: `url(${info.image})`}}>
            { renderContent() }
          </div>
        }

          {info.video && <div className="shape-div" >
            <video autoPlay={true} muted={true} loop={true} className={'video'}>
              <source src={`/assets/images/aat.mp4`} type="video/mp4" />
            </video>
            {/*<YouTube videoId={'D5IocmH3QTY'} opts={youtubeOpts} className={'video-style'} />*/}
            { renderContent() }
          </div>
          }


        <svg width="0" height="0">
          <defs>
            <clipPath id="clip-shape"
                      clipPathUnits="objectBoundingBox">
              <path
                d="M1,0.957q-0.014,0.006-0.029,0.011c-0.069,0.013-0.139,0.017-0.209,0.016-0.061-0.001-0.121-0.006-0.182-0.012-0.058-0.006-0.116-0.013-0.175-0.018-0.061-0.006-0.122-0.01-0.183-0.01C0.142,0.943,0.071,0.948,0,0.957V0.042C0.071,0.033,0.142,0.029,0.213,0.029c0.061,0,0.122,0.005,0.183,0.01,0.058,0.006,0.116,0.012,0.175,0.018,0.061,0.006,0.121,0.011,0.182,0.012,0.07,0.001,0.14-0.003,0.209-0.016q0.014-0.003,0.029-0.011V0.957Z" />
            </clipPath>
          </defs>
        </svg>
      </div>
    )
}

export default Hero
