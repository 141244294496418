// import { useState, useEffect, useContext } from 'react'
import { ButtonInterface } from './Button.interface'

/// TODO: update interface/arguments for Button
const Button = ({testID, text, type, link }:ButtonInterface) => {

    return(
        <a  href={link} target={'_blank'}
            data-testid={ testID }
            data-object-type={ type ?? ""}
            className={ `Button` } rel="noreferrer">
          { text }
        </a>
    )

}

export default Button
