// import { useState, useEffect, useContext } from 'react'
import {MainPageInterface} from './MainPage.interface'
import {Container, Row, Col} from "react-bootstrap";
import {
  Hero,
  Blob,
  Booking,
  ContactInfo
} from '../../molecules'
import {BottomBlob} from "../../organisms";
import { Heading, Paragraph } from '../../atoms'


const MainPage = ({ testID, page }: MainPageInterface) => {

  return (
    <div data-testid={testID}
         className={`MainPage`}>
      <Hero info={page} type={page.position}/>
      <Container fluid>
        <Row>
          <Col md={7} >

            <Heading text={page.pageTitle} type={'h1'}/>
            {
              page.content.map( (item, index) => {
                return(
                  <>
                    { item.title && <Heading text={item.title} type={'h3'} />}
                    <Paragraph text={ item.content} type={'regular'} renderer={'html'}/>
                  </>
                )
              })
            }

          </Col>
          <Col md={5}><Blob><Booking /></Blob></Col>
        </Row>
        <Row>
          <Col md={4}><ContactInfo /></Col>
          <Col md={8}><BottomBlob /></Col>
        </Row>
      </Container>
    </div>
  )
}

export default MainPage
